.footer {
  background: var(--szary-100, #f2f2f2);
  min-height: 183px;
  padding: 49px 20px;
  z-index: 1;
  margin-top: 150px;
  @include mobile-only {
    margin-top: 40px;
  }

  &__container {
    margin: 0 auto;
    max-width: 1140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include mobile-only {
      flex-direction: column;
      gap: 48px;
    }
  }
  &__socials {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__column {
    @include mobile-only {
      text-align: center;
    }
    &:nth-child(2) {
      p {
        margin-bottom: 9px;
      }
    }
    &:nth-child(4) {
      display: flex;
      flex-direction: column;
    }
    p {
      color: var(--szary-600, #666);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      a {
        font-weight: 800;
      }
    }

    h3 {
      color: var(--szary-600, #666);
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 156%;
    }
  }
  &__information {
    color: var(--szary-600, #666);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 178%;
    cursor: pointer;
  }
}
