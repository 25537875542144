.back {
  display: flex;
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
  font-weight: 500;
  padding: 0;
  .svg {
    margin-right: 10.5px;
  }
}
