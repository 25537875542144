.step{
    display: flex;
    &__item{
        margin-right: 4px;
        &:last-child{
            margin-right: 0;
        }
        width: 24px;
        height: 8px;
        border-radius: 15px;
        background: $gray-7;
        transition: 0.3s;
        &--current{
            background: #086852;
        }
    }
}