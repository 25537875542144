.summary {
  &__item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    @include body1;
    color: $gray700;
    margin-bottom: 21px;
    transition: 0.3s;
    &--hidden {
      height: 0;
      margin-bottom: 0;
      opacity: 0;
    }
  }
  .svg {
    height: 24px;
  }
  &__wrapper {
    overflow: auto;
    padding-right: 20px;
    margin-right: -20px;
    @include lg {
      padding-bottom: 20px;
      height: 359px;
      height: calc(100% - 130px);
    }
  }
  &__buttons {
    position: relative;
  }
  &__button-loader {
    right: 32px;
    top: 22px;
    position: absolute;
    .small-loader__dot {
      background: white;
    }
  }
  &__translations {
    transition: 0.3s;
  }
  &__item-value {
    color: $gray800;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    div &--specialization {
      background: $gray100;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 14px;
      height: 24px;
      @include body2;
      color: $gray700;
      .svg {
        margin-right: 8px;
        height: 16px;
        width: auto;
      }
    }
  }
  &__title {
    @include body1;
    color: $gray700;
  }
  &__submit {
    border-radius: 30px;
    margin-top: 40px;
    @include lg {
      margin-top: 10px;
    }
  }
  &__flag {
    width: 17px;
    max-height: 13px;
  }
  &__price {
    font-weight: 500;
    font-size: 14px;
    line-height: 138%;
    color: $gray700;
    border-top: 0.5px solid $gray300;
    border-bottom: 0.5px solid $gray300;
    padding: 16px 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    &--value {
      font-weight: 800;
      color: $gray800;
      font-size: 14px;
      line-height: 17px;
    }
  }
  &__files-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 138%;
    margin-top: 24px;
    color: $gray700;
    &--add {
      cursor: pointer;
      font-weight: 800;
      font-size: 14px;
      line-height: 17px;
      color: $gray800;
    }
  }
  &__file {
    display: grid;
    width: 100%;
    @include body1;
    text-overflow: ellipsis;
    overflow: hidden;
    // white-space: nowrap;
    // justify-content: space-between;
    align-items: center;
    background: #f4f4f4;
    padding: 0px 16px;
    border-radius: 4px;
    z-index: 1;
    gap: 12px;
    word-break: break-all;
    grid-template-columns: min-content auto 1fr;
    margin-bottom: 8px;
    min-height: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    &--close-hidden {
      visibility: hidden;
      pointer-events: none;
      display: none;
    }
    .svg--file {
      width: 20.5px;
      height: 24.6px;
      min-width: 20.5px;
    }
    .svg--close {
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: block;
    }
    &--download {
      cursor: pointer;
      .svg--download {
        width: 18px;
        height: 18px;
      }
    }
    span {
      margin-left: auto;
    }
  }
  &__download-all {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $green500;
    cursor: pointer;
  }
  &__files-wrapper {
    display: flex;
    flex-direction: column;
  }
  &__badge {
    border-radius: 20px;
    background-color: $gray100;
    padding: 2px 14px;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 8px;
    align-self: start;
    height: 24px;

    display: grid;
    grid-template-columns: min-content min-content;
    @include body2;
    color: $gray700;
    .svg {
      height: 13px;
      width: 17px;
    }
  }
  &__languages {
    gap: 6px;
    margin-top: 15px;
    margin-bottom: 21px;
    display: grid;
    grid-template-columns: min-content 11px 1fr;
    .svg--arrow-right {
      color: $gray600;
      height: 24px;
    }
  }

  &__languages-item {
    display: grid;
    grid-template-columns: auto-fit(min-content);
    flex-wrap: wrap;
    gap: 6px;
  }
  &__incorrect-format {
    display: flex;
    margin-top: 24px;
    flex-direction: column;
    .summary__file {
      margin-bottom: 8px;
    }
  }
  &__incorrect-format-header {
    @include body1;
    color: $gray800;
  }
  &__incorrect-format-description {
    @include body2;
    color: $gray600;
    margin-top: 3px;
    margin-bottom: 16px;
  }
  &__translated-files-header {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 138%;
    color: #282828;
    color: #2d2d2d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 24px;
  }
}
