.deadline-modal {
  &__hours {
    display: grid;
    grid-template-columns: 1fr;
    width: max-content;
  }
  &__header {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: $gray800;
    text-align: center;
    margin-left: -4px;
    margin-top: 5px;
  }
  &__hour {
    font-weight: 700;
    font-size: 20px;
    color: $gray600;
    line-height: 32px;
    p {
      width: 100%;
      text-align: center;
    }
    height: 40px;
    border-radius: 4px;
    width: 156px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.4s ease-out;
    @include lg {
      &:hover {
        box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.09);
        background: #ffffff;
      }
    }
    &--active {
      background: #ffffff;
      box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.09);
      color: $green500;
      cursor: default;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    @include lg {
      padding: 40px;
      flex-direction: row;
    }
  }
  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    width: 100%;
  }
  &__button {
    height: 48px;
    width: 100%;
    @include lg {
      max-width: 272px;
    }
  }
  &__hours-list {
    overflow: auto;
    height: 335px;
    padding: 22px 0;
    padding-right: 20px;
  }
  div &__calendar {
    width: 369px;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    padding-top: 20px;
    border: none;
    font-family: 'Lato', sans-serif;
    .react-calendar__tile {
      width: 40px;
      height: 40px;
      flex: unset !important;
      border-radius: 8px;
      color: $gray800;
      font-weight: 600;
      background-color: #f2f2f2;
      background: #f2f2f2;
      transition: 0.3s;
      font-size: 20px;
      &:disabled {
        background-color: unset;
        color: $gray400;
        @include lg {
          &:hover {
            background-color: unset;
          }
        }
      }
      &.react-calendar__month-view__days__day--neighboringMonth {
        color: $gray200;
        background-color: unset;
      }
      @include lg {
        &:hover {
          background-color: #bceadf;
        }
      }
      &--active {
        background-color: $green500;
        color: white !important;
        @include lg {
          &:hover {
            background-color: $green500;
          }
        }
        &:focus {
          background-color: $green500;
        }
      }
    }
    .react-calendar__month-view__days {
      padding-inline: 20px;
      padding-bottom: 20px;
      display: grid !important;
      grid-template-columns: repeat(7, 1fr);
      gap: 8px;
    }
    .react-calendar__month-view__weekdays__weekday {
      flex: unset !important;
      width: 40px;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      height: 40px;
      text-transform: capitalize;
      color: $gray700;
      padding: 0;
      margin-bottom: 4px;
      abbr {
        text-decoration: none;
      }
    }
    .react-calendar__month-view__weekdays {
      padding-inline: 20px;
      display: grid !important;
      grid-template-columns: repeat(7, 1fr);
      gap: 8px;
    }
    .react-calendar__navigation button {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      color: $gray800;
      text-transform: capitalize;
      &:disabled {
        background: none;
        color: $gray300;
      }
    }
    button.react-calendar__navigation__next-button {
      border-radius: 0 8px 0 0;
      transition: 0.3s;
      font-size: 28px;
    }
    button.react-calendar__navigation__prev-button {
      border-radius: 8px 0 0 0;
      transition: 0.3s;
      font-size: 28px;
    }
    .react-calendar__navigation button:enabled:focus {
      background: none;
    }
    .react-calendar__navigation__label {
      pointer-events: none;
    }
  }
}
