.add-user-modal {
  &__wrapper {
    width: 100%;
  }
  &__header {
    @include heading1;
  }
  &__form {
    margin-top: 56px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 39px;
  }
  &__form-row {
    display: grid;
    @include lg {
      grid-template-columns: 1fr 1fr;
    }
    grid-gap: 16px;
    .input__label {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $gray700;
      width: 272px;
    }
  }
}
