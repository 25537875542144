.add-translated-file-modal {
  &__wrapper {
    border: 1px dashed #bfbfbf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 91px;
    margin-top: 55px;
    margin-bottom: 16px;
    transition: 0.3s;
    &--dragging {
      opacity: 0.5;
    }
    &--error {
      border-color: $error;
    }
    @include lg {
      margin-top: 55px;
      height: 150px;
      width: 561px;
    }
    .svg {
      height: 32px;
      width: 32px;
      color: #a6a6a6;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 138%;
    color: #a6a6a6;
    margin-top: 10px;
    &--download {
      font-weight: 800;
      @include lg {
        font-weight: 500;
      }
      color: $green500;
      cursor: pointer;
    }
  }
  &__error {
    color: #db4446;
    font-size: 10px;
    line-height: 14px;
    margin-top: -8px;
    height: 0;
    transition: 0.3s;
    overflow: hidden;
    &--show {
      height: 14px;
    }
  }
  &__files {
    width: 100%;
    max-height: 136px;
    overflow-y: auto;
    .summary__file {
      width: 100%;
      height: 40px;
      padding: 0 16px;
      .svg {
        margin-bottom: unset;
      }
    }
  }
  &__buttons {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    width: 100%;
  }
  .small-loader {
    margin-left: auto;
  }
}
