.incorrect-files-form {
  &__top {
    display: grid;
    gap: 48px;
    @include lg {
      grid-template-columns: repeat(2, 1fr);
      gap: 64px;
      margin-top: 40px;
    }
  }
  &__top-container {
    flex-basis: 50%;
    text-align: center;
    .svg {
      width: 273px;
    }
    @include lg {
      text-align: left;
    }
  }
  &__top-text {
    @include heading4;
    margin-bottom: 16px;
  }
  &__top-subtext {
    @include body2;
  }
  &__bottom {
    margin-top: 48px;
  }
  &__row {
    display: grid;
    grid-template-columns: 1fr ;
    gap: 16px;
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0;
    }
    @include lg {
      grid-template-columns: 1fr 1fr;
      gap: 24px;
    }
  }
}
