.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  &--disabled {
    path {
      color: $gray200;
    }
  }

  &__next {
    transform: rotate(180deg);
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    .svg {
      height: 10px;
    }
  }
  &__prev {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    .svg {
      height: 10px;
    }
  }
  &__page {
    color: $gray800;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.38;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 8px;
    &--current {
      background-color: $gray700;
      color: white;
    }
    @include lg {
      width: 32px;
      height: 32px;
    }
  }
}
