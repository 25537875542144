.cookie-banner {
  padding: 28px 24px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.1);
  width: calc(100% - 40px);
  max-width: 562px;
  position: fixed;
  bottom: 44px;
  z-index: 9999;
  left: 50%;
  transform: translate(-50%, 150%);
  opacity: 0;
  pointer-events: none;
  z-index: -2;
  transition: all 0.3s ease-in-out;

  &--show {
    opacity: 1;
    pointer-events: all;
    z-index: 9999;
    transform: translate(-50%, 0);
  }
  &__title {
    color: #404040;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
    a {
      color: #2eb092;
      text-decoration: none;
      font-weight: 700;
    }
  }
  &__buttons {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    @include mobile-only {
      flex-direction: column;
    }
    button {
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      padding-inline: 8px;
      line-height: normal;
      justify-content: center;
      align-items: center;
      gap: 8px;
      min-height: 32px;
      //   height: 32px;
      color: #2eb092;
      border-radius: 30px;
      border: 2px solid var(--green-500, #2eb092);
      background: #fff;
      flex: 1;
      cursor: pointer;
      @include mobile-only {
        width: 100%;
        height: 40px;
      }
      &:first-child {
        background: var(--green-500, #2eb092);
        color: #fff;
      }
    }
  }
}
