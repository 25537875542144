.svg {
  // width: fit-content;
  height: 100%;
  &--circle {
    fill: transparent;
    stroke: $black;
  }
  &--ties {
    position: absolute;
    width: 100%;
    height: auto;
    max-height: 100%;
    bottom: 0;
    left: 0;
    z-index: 0;
  }
  &--close {
    cursor: pointer;
    width: 14px;
  }
  &--sync {
    path {
      fill: #f98600;
    }
  }
}
