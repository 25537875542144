.checkbox {
  margin-top: 24px;
  &:hover {
    cursor: pointer;
    .checkbox__dummy-input {
      border: 2px solid var(--niebieski-300, #317d6b);
    }
  }

  input {
    display: none;
  }
  &__dummy-input {
    border-radius: 2px;
    border: 2px solid var(--szary-300, #bfbfbf);
    width: 16px;
    height: 16px;
    display: flex;
    flex: 1;
    max-width: 16px;
    position: relative;
    transition: 0.3s;
    box-sizing: border-box;
  }
  &__svg {
    opacity: 0;
    transition: 0.3s;

    &--checked {
      opacity: 1;
    }
  }
  &__option {
    display: flex;
    gap: 10px;
    align-items: center;
    &--selected {
      .checkbox__dummy-input {
        border: 2px solid var(--niebieski-600, #2eb092) !important;
        background: var(--niebieski-500, #2eb092);
      }
    }
  }
  &__title-wrapper {
    color: #666;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 134%;
    a {
      color: #2eb092;
      cursor: pointer;
    }
  }
}
