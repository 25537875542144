.change-password-modal {
  max-width: 369px;
  &__add-button {
    height: 48px;
  }
  div &__form {
    margin-top: 40px;
  }
  &__row {
    position: relative;
  }
  &__icon {
    position: absolute;
    top: 50px;
    right: 16px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
