.input {
  background: $white;
  border: 1px solid $gray200;
  border-radius: 4px;
  padding: 16px 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  transition: 0.3s;
  max-height: 48px;
  &--removable {
    padding-right: 38px;
  }
  &::placeholder {
    color: $gray400;
  }
  &:focus {
    outline: none;
    border: 1px solid $green600;
  }
  @include lg {
    &:hover {
      border: 1px solid $gray400;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    &--error {
      .input {
        border-color: $error;
      }
      .input__error {
        color: $error;
      }
    }
  }
  &__label {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 6px;
    line-height: 18px;
    color: $gray700;
    display: flex;
    align-items: center;
    height: 18px;
  }
  &__removable {
    position: absolute;
    top: 29px;
    right: 8px;
    font-size: 20px;
    cursor: pointer;
    color: $gray400;
    .svg {
      display: block;
      width: 24px;
      height: 24px;
      &--close {
        scale: 1.5;
      }
      &--search {
        width: 20px;
      }
    }
  }
  &__optional {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $gray-1;
    margin-left: 6px;
  }
  &__double-input {
    display: flex;
    input:first-child {
      max-width: 30%;
      margin-right: 10px;
    }
    input:last-child {
      max-width: 70%;
    }
  }
  &__error {
    @include body1;
    color: $error;
    margin-top: 4px;
  }
}
