.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  z-index: 5;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 176px 294px;
  transition: 0.5s;
  transform: translateX(100%);
  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(45, 45, 45, 0.05);
  &--show {
    transform: translateX(0);
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 67px;
    margin-bottom: 95px;
    .svg--logo {
      height: 22px;
      width: 103px;
    }
    .svg--close{
        width: 24px;
        height: 24px;
    }
  }
}
