.homepage {
  overflow: hidden;
  @include mobile-only {
    padding-bottom: 30px;
  }
  &__header {
    font-weight: 800;
    line-height: 1.45;
    color: $gray800;
    margin-bottom: 24px;
    font-size: 24px;
    @include lg {
      font-size: 36px;
    }
  }
  &__wrapper {
    margin-top: 10px;
    @include lg {
      width: 530px;
      margin-top: 176px;
    }
  }
  &__subtitle {
    font-weight: 500;
    color: $gray600;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 1.6;
    @include lg {
      font-size: 16px;
      line-height: 1.52;
    }
  }
  &__button {
    width: 100%;
    max-width: 400px;
    @include lg {
      width: 284px;
    }
  }
  &__svg {
    height: auto;
    display: block;
    right: 0;
    width: 75vw;
    top: 100px;
    margin-left: auto;
    margin-top: 30px;
    margin-right: -20px;
    @include lg {
      margin-top: 0;
      margin-left: unset;
      position: absolute;
      top: 132px;
      left: calc((100% - 1140px) / 2 + 611px);
      width: 560px;
    }
  }
  &__background {
    position: absolute;
    z-index: -1;
    width: 70%;
    right: -300px;
    height: auto;
    width: 664px;
    height: 638px;
    top: -114px;
    @include lg {
      height: 908px;
      width: 946px;
      left: calc((100% - 1140px) / 2 + 571px);
    }
  }
  &__desktop-br {
    display: none;
    @include lg {
      display: block;
    }
  }
}
