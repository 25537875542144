.language-form {
  &__selected-languages {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .select__multi-value {
      display: grid;
      align-items: center;
      gap: 8px;
      grid-template-columns: 27px max-content 13px;
      background: #f4f4f4;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      width: fit-content;
      height: 32px;
      @include lg {
        height: 24px;
      }
    }
  }
  &__wrapper {
    overflow: auto;
    max-height: 90vh;
  }
  &__select {
    width: 100%;
    position: relative;
  }
  &__placeholder {
    position: absolute;
    top: 19px;
    left: 16px;
    color: $inputDefault;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    visibility: visible;
    .svg {
      margin-right: 16px;
      path {
        stroke: $inputDefault;
      }
    }

    &--hidden {
      visibility: hidden;
    }
  }
  &__modal {
    max-height: unset;
    .select__value-container .select__multi-value {
      display: none;
    }
  }
  div .select__multi-value &__close {
    width: 16px;
    height: 16px;
    cursor: pointer;
    scale: 1.7;
    @include lg {
      scale: 1;
    }
  }
  &__close-wrapper {
    display: flex;
    align-items: center;
  }
  &__language {
    display: flex;
    align-items: center;
    padding: 0 4px;
    cursor: pointer;
    @include body1;
    color: $gray800;
    .svg--flag {
      width: 20px;
      height: 15px;
    }
  }
  &__most-popular {
    display: flex;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
    flex-direction: column;
    @include lg {
      align-items: flex-start;
      flex-direction: row;
    }
  }
  &__most-popular-header {
    font-size: 14px;
    color: $gray800;
    margin-bottom: 16px;
    @include lg {
      margin-bottom: 0;
    }
  }
  &__most-popular-languages {
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    @include lg {
      margin-left: 12px;
    }

    .language-form__language {
      margin-right: 16px;
      height: 24px;
    }
    .language-form__language:last-child {
      margin-right: 0;
    }
  }
  &__all-languages-language {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    height: 24px;

    @include body1;
    .svg--flag {
      width: 20px;
      height: 15px;
    }
  }
  &__all-languages-wrapper {
    align-items: center;
    row-gap: 4.5px;
    display: grid;
    grid-gap: 12px;
    gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    @include lg {
      grid-template-columns: repeat(auto-fill, minmax(179px, 1fr));
    }
    width: 100%;
    flex-wrap: wrap;
    max-height: 192px;
    margin-top: 12px;
    overflow-y: auto;
  }
  &__all-languages {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
  }
  &__buttons {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    width: 100%;
    margin-top: 24px;
    .btn {
      max-width: 175px;
      height: 40px;
      border-radius: 30px;
      &:last-child {
        margin-left: auto;
      }
    }
  }
}
