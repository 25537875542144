.container-right {
  width: 100%;
  // max-width: 755px;
  padding: 27px 17px;
  background-color: $white;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin-bottom: 65px;
  &--dragging {
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.12);
  }
  @include lg {
    transition: 0.3s;
    margin-bottom: unset;
    // position: fixed;
    position: sticky;
    min-height: 578px;
    height: 100%;
    width: 369px;
    padding: 39px 20px 39px 40px;
    height: calc(100vh - 304px);
    max-height: 678px;
  }
  &__placeholder {
    position: relative;
    @include lg {
      flex-basis: 369px;
      height: 578px;
    }
  }
  &__slider {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-x: hidden;
    @include lg {
      max-width: 309px;
      padding-right: 20px;
    }
  }
  &__slide {
    min-width: 100%;
    margin-right: 24px;
    @include lg {
      transition: 0.3s;
    }

    &:last-of-type {
      margin-right: 0;
    }
    transform: translateX(0);
    &--second {
      transform: translateX(calc(-100% - 24px));
    }
  }
  &__loader {
    width: 100%;
  }
}
