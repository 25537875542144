.succesful-password-reset {
  &__title {
    @include heading2;
    font-weight: 800;
    font-size: 20px;
    line-height: 145%;
    color: $gray600;
  }
  &__subtitle{
    margin-bottom: 40px;
  }
}
