.contact-data {
  &__type {
    background: #d9d9d9;
    border-radius: 20px;
    padding: 6px 16px;
    width: max-content;
    @include body1;
    color: black;
    margin-bottom: 32px;
    &--company {
      background: #404040;
      color: #ffffff;
    }
  }
  &__item {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4px;
    margin-bottom: 16px;
  }
  &__item-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 145%;
    color: #929292;
    &--wrap {
      display: flex;
      .contact-data__item-value:first-child {
        margin-right: 8px;
      }
    }
  }
  &__item-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 145%;
    color: #494949;
  }
  &__row {
    display: grid;
    grid-template-columns: 140px 1fr;
  }
}
