.mobile-dashboard-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $gray800;
  padding: 17px 20px;
  z-index: 5;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 184px 176px 294px;
  transition: 0.5s;
  transform: translateX(100%);
  &--show {
    transform: translateX(0);
  }
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 56px;
    margin-bottom: 64px;
    .svg--logo {
      height: 22px;
      width: 103px;
      path {
        fill: $gray200;
      }
    }
  }
  &__close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    color: $gray300;
    .svg--close {
      width: 24px;
      scale: 1.5;
    }
  }
  &__badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: $green500;
    border-radius: 50%;
    color: $white;
    font-weight: 900;
    font-size: 16px;
    line-height: 152%;
    letter-spacing: -0.8px;
  }
  &__user {
    display: grid;
    grid-template-columns: 40px max-content;
    grid-gap: 16px;
    align-items: center;
    h2 {
      font-weight: 500;
      font-size: 16px;
      line-height: 152%;
      color: $gray200;
    }
  }
  &__link {
    width: 100%;
    height: 48px;
    display: grid;
    grid-template-columns: 24px max-content;
    grid-gap: 16px;
    align-items: center;
    padding: 0 24px;
    color: white;
    border-radius: 5px;
    transition: 0.3s;
    cursor: pointer;
    path {
      fill: white;
    }
    @include lg {
      &:hover {
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
        background-color: $gray700;
      }
    }
    &--active {
      cursor: default;
      background-color: $gray700;
      color: $green500;
      path {
        fill: $green500;
      }
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    }
  }
  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
  &__footer {
    margin-top: 190px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }
  &__footer-button {
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
    transition: 0.3s;
    height: 48px;
    padding: 0 24px;
    align-items: center;
    display: grid;
    grid-template-columns: 24px max-content;
    grid-gap: 16px;
    cursor: pointer;
    @include lg {
      &:hover {
        background-color: $gray700;
      }
    }
  }
  &__footer-label {
    @include heading4;
    color: $gray200;
  }
}
