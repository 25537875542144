.filters {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: white;
  z-index: 2;
  box-shadow: 0px 2px 30px #00000018;
  border-radius: 10px 10px 0px 0px;
  height: 643px;
  max-height: 90vh;
  padding: 0 20px;
  transform: translateY(100%);
  transition: 0.3s;
  &--open {
    transform: translateY(0);
  }
  div.select__wrapper {
    width: 100%;
  }
  div.select__wrapper:first-of-type {
    margin-bottom: 24px;
  }
  &__header {
    font-weight: 800;
    font-size: 20px;
    line-height: 145%;
    text-align: center;
    color: $gray800;
    margin-top: 32px;
    margin-bottom: 40px;
  }
  &__buttons {
    margin-top: 238px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    transition: 0.3s;
    z-index: -1;
    &--show {
      z-index: 1;
      background: #0000004d;
    }
  }
}
