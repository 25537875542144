.single-order {
  position: fixed;
  right: 0;
  z-index: 2;
  background: linear-gradient(180deg, #404040 0%, #2d2d2d 12.82%);
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.09);
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  opacity: 1;
  pointer-events: all;
  width: 100%;
  // top: 0;
  height: 100%;
  @include lg {
    height: calc(100% - 56px);
    top: 56px;
    width: 370px;
  }
  &--hidden {
    opacity: 0;
    z-index: -1;
    pointer-events: 0;
  }
  &__header {
    height: 122px;
  }
  &__header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 34px 20px 24px 40px;
    h2 {
      @include heading2;
      color: $gray100;
    }
  }
  &__modal-text {
    margin-top: 39px;
    margin-bottom: 72px;
    text-align: center;
    width: 560px;
    font-size: 16px;
    line-height: 152%;
    color: $gray600;
    &--bold {
      font-weight: 800;
    }
  }
  &__close {
    width: 20px;
    .svg {
      display: block;
      width: 24px;
      height: 24px;
      scale: 1.2;
    }
  }
  &__form {
    height: 100%;
    display: flex;
    flex-direction: column;
    border: none;
  }
  &__content {
    background: #ffffff;
    border-radius: 20px 20px 0px 0px;
    flex: 1;
    padding: 40px 20px 40px 40px;
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  &__input {
    .input__label {
      display: none;
    }
    .input {
      width: 137px;
      height: 32px;
      border-radius: 20px;
      border: 1px solid #d9d9d9;
      font-weight: 400;
      font-size: 10px;
      line-height: 134%;
      text-align: center;
    }
  }
  &__content-wrap {
    flex: 1;
    overflow-y: auto;
    @include lg {
      max-height: calc(100vh - 325px);
    }
    padding-right: 20px;
    &--status {
      display: grid;
      grid-template-columns: 1fr;
      flex: unset;
      overflow-y: unset;
      grid-gap: 16px;
    }
    .select__control {
      border-radius: 4px;
    }
  }
  &__files-header {
    margin-bottom: 16px;
  }
  &__header-select {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-left: 40px;
  }
  &__header-item {
    margin-right: 16px;
    color: $gray200;
    border-radius: 20px;
    transition: 0.3s;
    font-weight: 800;
    font-size: 12px;
    line-height: 1.6;
    height: 24px;
    padding-inline: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    &:last-child {
      margin-right: 0;
    }
    &--active {
      background: rgba(102, 102, 102, 0.5);
      box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.3);
      color: white;
      cursor: default;
    }
  }
  &__buttons {
    padding-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    .btn {
      height: 40px;
    }
  }
}
