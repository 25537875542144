.cookie-modal {
  z-index: 9999;
  left: 50%;
  transform: translate(-50%, 150%);
  position: fixed;
  width: calc(100% - 40px);
  padding: 28px 22px;
  max-width: 562px;
  bottom: 44px;
  max-height: 655px;
  overflow: auto;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  &--show {
    opacity: 1;
    pointer-events: all;
    z-index: 9999;
    transform: translate(-50%, 0);
  }
  &__title {
    color: var(--szary-800, #2d2d2d);
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 152%;
  }
  &__subtitle {
    color: #404040;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
    margin-bottom: 40px;
    a {
      color: #2eb092;
      text-decoration: none;
      font-weight: 700;
    }
  }
  &__options {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__option {
    display: flex;
    padding: 0px 23px;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    &--open {
      max-height: 500px;
      .cookie-modal__open-button {
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }
  &__option-wrapper {
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    max-height: 50px;
    padding: 14px 0;
    transition: all 0.3s ease-in-out;
    &--open {
      max-height: 500px;
      .cookie-modal__open-button {
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }
  &__option-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      gap: 8px;
      padding-bottom: 14px;
    }
    h3 {
      color: var(--szary-700, #404040);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      padding-bottom: 14px;
    }
  }
  &__open-button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      transform: rotate(270deg);
      transition: all 0.3s ease-in-out;
      path {
        fill: #a6a6a6;
      }
    }
  }
  &__necessary {
    color: var(--szary-500, #808080);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }
  &__option-description {
    color: var(--szary-600, #666);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
  }
  &__buttons {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    @include mobile-only {
      flex-direction: column;
    }
    button {
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      padding-inline: 8px;
      line-height: normal;
      justify-content: center;
      align-items: center;
      gap: 8px;
      min-height: 40px;
      //   height: 32px;
      color: #2eb092;
      border-radius: 30px;
      border: 2px solid var(--green-500, #2eb092);
      background: #fff;
      flex: 1;
      cursor: pointer;
      @include mobile-only {
        width: 100%;
        height: 40px;
      }
      &:last-child {
        background: var(--green-500, #2eb092);
        color: #fff;
      }
    }
  }
}
