@import './_reset';
@import './_variables';
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');

html {
  scroll-behavior: smooth;
}
body {
  line-height: 1.5;
  font-size: 16px;
  font-family: $primaryFont;
}
.Toastify__toast-body {
  .Toastify__toast-icon {
    width: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  > div:last-child {
    font-weight: 400;
    font-size: 12px;
    line-height: 138%;
    @media screen and (min-width: 1024px) {
      font-size: 10px;
      line-height: 1.34;
    }
    color: $gray600;
  }
}
#root {
  position: relative;
  overflow: hidden;
  @media screen and (min-width: 1024px) {
    min-height: 100vh;
  }
  display: flex;
  flex-direction: column;
}
.Toastify__toast {
  @media screen and (max-width: 1024px) {
    width: calc(100% - 40px);
    margin-inline: auto;
    margin-top: 16px;
  }
  border-radius: 5px;
}
.Toastify__toast-container--bottom-right {
  @media screen and (max-width: 1024px) {
    bottom: 107px;
  }
}
