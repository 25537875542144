.small-loader {
  display: flex;
//   width: 79px;
  justify-content: center;
  align-items: center;
  &__dot {
    height: 5px;
    width: 9px;
    background: #086852;
    border-radius: 3px;
    margin-right: 6px;
    animation: dot 1.5s ease-in-out infinite;
    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:last-child {
      animation-delay: 1s;
      margin-right: 0;
    }
  }
  @keyframes dot {
    0% {
      transform: translateY(0);
    }
    14.2% {
      transform: translateY(0);
    }
    28.4% {
      transform: translateY(0);
    }
    42.6% {
      transform: translateY(0);
    }
    56.8% {
      height: 2.5px;
    }
    71% {
      width: 4px;
      height: 4px;
      transform: translateY(-10px);
    }
    85% {
      transform: translateY(0);
      height: 1.25px;
      width: 9px;
    }
    100% {
      transform: translateY(0);
    }
  }
}
