@use '../../breakpoints.scss' as *;

.modal {
  // position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  height: 100%;
  padding: 32px 20px;
  align-items: center;
  @include mobile-only {
    overflow: auto;
  }
  @include lg {
    height: 643px;
    justify-content: center;
    padding: 36px 17px;
    max-width: 751px;
    height: fit-content;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    padding: 40px 94px;
  }
  &__wrapper {
    height: 100%;
    z-index: 1000;
    // transform: translateY(100%);
    position: fixed;
    overflow: auto;
    max-height: 90vh;
    bottom: 0;
    transition: 0.3s;
    background-color: $white;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: fit-content;
    overflow: auto;
    &--open {
      transform: unset;
    }
    @include lg {
      // transform: translate(-50%, -50%);
      bottom: unset;
      width: unset;
      border-radius: 10px;
    }
  }
  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    &--open {
      opacity: 1;
      pointer-events: all;
    }
  }
}
