$darkBlue: #222d5c;

$primaryFont: 'Lato', sans-serif;

$white: #fff;
$black: #000;

$lightGray: #ebebeb;
$gray-1: #767676;
$gray-2: #cecece;
$gray-3: #666;
$gray-4: #929292;
$gray-5: #f3f3f3;
$gray-6: #cdcdcd;
$gray-7: #dbdcdb;
$midGray: #d9d9d9;
$darkGray: #acacac;
$darkerGray: #5c5c5c;
$textColor: #494949;

$error: #db4446;
$gray100: #f2f2f2;
$gray200: #d9d9d9;
$gray300: #bfbfbf;
$gray400: #a6a6a6;
$gray500: #808080;
$gray600: #666666;
$gray700: #404040;
$gray800: #2d2d2d;

$green500: #2eb092;
$green600: #086852;

$goldGradient: linear-gradient(90deg, #fbc777 -16.09%, #bb9b63 84.38%, #fde4ab 206.37%, #ac8243 342.72%);
$gold: #d1b978;
$green: #2d3f25;
$text: #282828;
$background1: #f4f4f4;
$background2: #e0e2df;
$inputDefault: #a3a4a3;
$disabled: #acacac;
$warning: #f98600;

// Screen sizes
$screen-sm: 420px;
$screen-md: 756px;
$screen-lg: 1024px;
