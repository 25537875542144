@mixin body1 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 138%;
  color: $text;
}

@mixin heading1 {
  font-weight: 800;
  font-size: 20px;
  line-height: 145%;
  color: $gray800;
}

@mixin heading2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: $text;
}

@mixin heading3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.6;
  color: $gray800;
}

@mixin heading4 {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.6;
  color: $gray600;
}
@mixin body2 {
  font-weight: 400;
  font-size: 10px;
  line-height: 134%;
  color: #7a7a7a;
}
@mixin inputTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $gray500;
}

@mixin goldGradientText {
  background: $goldGradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
