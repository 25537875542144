.restore-password{
  display: flex;
  flex-direction: column;
  align-items: center;
  @include lg {
    align-items: flex-start;
  }
  &__box {
    display: none;
    @include lg {
      z-index: -1;
      position: absolute;
      width: calc((100% - 1140px) / 2 + 851px);
      box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.09);
      height: 100vh;
      top: 0;
      left: 0;
      display: block;
    }
  }
  &__wrapper {
    height: 100%;
    padding-top: 87px;
    max-width: 370px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    @include lg {
      align-items: flex-start;
    }
  }
  &__back {
    margin-bottom: 32px;
    margin-right: auto;
    .svg {
      height: 13px;
    }
  }
  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: $gray500;
    margin-bottom: 24px;
    text-align: center;
    @include lg{
      text-align: start;
    }
  }
  &__send-again{
    color: $green500;
    font-weight: 700;
    cursor: pointer;
  }
  &__title {
    @include heading2;
    color: $gray600;
    margin-bottom: 16px;
    margin-top: 48px;
    line-height: 145%;
  }
  &__input {
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 40px;
    }
  }
  &__forgot {
    @include body1;
    margin-bottom: 31px;
    color: $green600;
    cursor: pointer;
  }
  &__form {
    width: 100%;
    .input{
      height: 48px;
    }
  }
  &__svg {
    display: none;
    @include lg {
      position: absolute;
      top: 132px;
      left: calc((100% - 1140px) / 2 + 611px);
      width: 370px;
      height: auto;
      display: block;
    }
  }
  &__error {
    height: 56px;
    @include body1;
    color: $gray700;
    background: #fae3e3;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    margin-bottom: 0px;
    &--show {
      height: 56px;
      opacity: 1;
      visibility: visible;
      margin-bottom: 24px;
    }
    .svg {
      margin-right: 8px;
    }
  }
}
