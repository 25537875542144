.btn {
  box-sizing: border-box;
  border-radius: 4px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  border: none;
  background-color: $green500;
  color: $white;
  font-family: $primaryFont;
  font-weight: 700;
  padding: 15px 20px 16px;
  cursor: pointer;
  width: 100%;
  transition: 0.3s;
  .svg {
    margin: 0 8px;
  }
  path {
    transition: 0.3s;
  }
  @include lg {
    &:hover {
      background-color: #317d6b;
    }
  }
  include lg {
    padding: 15px 120px 16px;
  }
  &--stroke {
    background-color: transparent;
    border: 2px solid $green500;
    color: $green500;
    @include lg {
      &:hover {
        background-color: $green500;
        color: $white;
      }
    }
  }
  &--disabled {
    background-color: $darkGray;
    pointer-events: none;
  }
  &--stroke.btn--disabled {
    background-color: transparent;
    border: 2px solid $gray400;
    color: $gray400;
    @include lg {
      &:hover {
        background-color: transparent;
        color: $gray400;
      }
    }
  }
  &--cancel {
    @include lg {
      &:hover {
        background-color: $gray800;
        color: $white;
        border-color: $gray800;
      }
    }
  }
  &--round {
    border-radius: 30px;
  }
}
