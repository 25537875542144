.drop-file-input {
  background: $white;
  padding: 15px 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  transition: 0.3s;
  border: 1px dashed $gray300;
  border-radius: 4px;
  max-height: 48px;
  height: 48px;
  display: flex;
  z-index: 10;
  align-items: center;
  position: relative;
  justify-content: space-between;
  &__content {
    color: $gray400;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__input {
    display: none;
  }
  &__button {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    color: $green500;
    border: 2px solid $green500;
    border-radius: 4px;
    padding: 4px 10px;
    height: 32px;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s;
    @include lg {
      &:hover {
        background-color: $green500;
        color: $white;
      }
    }
  }
  &__remove {
    display: flex;
    align-items: center;
  }
  .summary__file {
    height: 32px;
    margin-bottom: unset;
    padding: 0 16px;
  }
  &__wrapper {
    &--error {
      .drop-file-input {
        border: 1px dashed $error;
      }
    }
  }
  &__outside {
    position: fixed;
    top: 0;
    left: 0;
    transform: scale(2);
    width: 100vw;
    height: 100vh;
    z-index: 9;
    transition: 0.6s;
    opacity: 0;
    background-color: black;
    pointer-events: none;
    &--show {
      opacity: 0.5;
    }
  }
}
