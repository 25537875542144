@import './components/LeftBar.scss';
@import './components/MobileDashboardMenu.scss';
@import './components/ChangePasswordModal.scss';

.dashboard {
  height: 100%;
  min-height: 100vh;
  display: flex;
  position: relative;
  &__right-side {
    width: 100%;
    margin-top: 56px;
    @include lg {
      margin-left: 80px;
    }
  }
  &__header {
    background: #ffffff;
    box-shadow: 0px 3px 20px rgba(45, 45, 45, 0.05);
    height: 56px;
    width: 100%;
    position: fixed;
    top: -1px;
    left: 0;
    z-index: 3;
    display: flex;
  }
  &__header-spacer {
    width: 80px;
    display: none;
    @include lg {
      display: flex;
    }
  }
  &__header-content {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    margin: 0 auto;
    max-width: 1180px;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    color: $gray600;
    justify-content: space-between;
    .svg {
      cursor: pointer;
    }
    @include lg {
      justify-content: flex-end;
      .svg {
        height: 10px;
        width: 10px;
        transition: 0.3s;
        transform: rotate(270deg);
      }
    }
  }
  &__header-logo {
    width: 103px;
    height: 22px;
    .svg {
      width: 100%;
      height: 100%;
    }
  }
  &__chevron {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    &--open {
      .svg {
        transform: rotate(90deg);
      }
    }
  }
  &__menu {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__dropdown-menu {
    transition: 0.3s;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    top: 100%;
    width: 200px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    border-radius: 4px;
    border-radius: 8px;
    right: 0;
    z-index: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray700;
    &--open {
      opacity: 1;
      pointer-events: all;
      z-index: 3;
    }
    p {
      background-color: white;
      transition: 0.3s;
      @include lg {
        &:hover {
          background-color: $gray100;
        }
      }
      padding: 11.5px 16px;
      &:last-child {
        margin-bottom: 0;
        color: $error;
      }
    }
  }
}
