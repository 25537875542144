.navigation {
  width: 100%;
  padding: 0 20px;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 20px rgba(45, 45, 45, 0.05);
  margin-bottom: 28px;
  background: #ffffff;
  position: fixed;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  @include lg {
    margin-bottom: 35px;
  }
  &__spacer{
    width: 100%;
    display: flex;
    flex: 1;
    height: 67px;
    @include lg {
      margin-bottom: 35px;
    }
  }
  &__wrapper {
    max-width: 1140px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__login {
    width: 113px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 30px;
    display: none;
    padding: 0 20px;
    font-size: 12px;
    @include lg {
      display: flex;
    }
  }
  &__mobile-menu {
    width: 24px;
    height: 24px;
    cursor: pointer;
    @include lg {
      display: none;
    }
    .svg {
      width: 24px;
      height: 24px;
    }
  }
  &__logo {
    cursor: pointer;
  }
  &--hidden {
    display: none;
  }
}
