@import './components/AddUserModal.scss';

.users-list {
  width: 100%;
  max-width: 1180px;
  padding: 0 20px;
  margin: 0 auto;
  min-height: calc(100vh - 56px);
  padding-bottom: 40px;

  &__header {
    @include heading1;
    margin-bottom: 0;
    padding-top: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include lg {
      margin-bottom: 27px;
    }
  }
  &__thead {
    @include body1;
    color: $gray500;
    height: 32px;
  }
  &__pagination {
    margin-top: 32px;
  }
  &__table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
  }
  &__td {
    text-align: left;
    vertical-align: middle;
    border-collapse: collapse;
    height: 56px;
    font-weight: 400;
    font-size: 14px;
    line-height: 134%;
    color: #2d2d2d;
    &--suspended {
      color: #808080;
    }
    &--inactive {
      color: #808080;
    }
    @include lg {
      @include body1;
      color: #2d2d2d;

      width: 10%;
    }
    &:first-child {
      padding-left: 25px;
      width: 73px;
      border: solid transparent;
      border-style: none solid solid none;
      border-radius: 5px 0 0 5px;
      @include lg {
        width: 20%;
        padding-left: 37px;
      }
    }
    &:last-child {
      padding-right: 14px;
      text-align: right;
      border: solid transparent;
      border-style: none solid solid none;
      border-radius: 0 5px 5px 0;
      @include lg {
        padding-right: 27px;
      }
    }
  }
  &__tr {
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
    margin-bottom: 4px;
    border-radius: 5px;
    transition: 0.3s;
    background-color: white;
    cursor: pointer;
    @include lg {
      &:hover {
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.18);
      }
    }
    &--active {
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.18);
    }
    &--inactive {
      opacity: 0.65;
    }
    div .select__control {
      height: 32px;
      min-height: unset;
    }
    .select__indicator {
      padding: 0 8px;
    }
    .svg {
      margin-bottom: 3px;
    }
  }
  &__role {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-weight: 800;
    font-size: 14px;
    line-height: 145%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    margin-right: auto;
    @include lg {
      @include body2;
      width: 89px;
      height: 24px;
      border-radius: 20px;
    }
    &--admin {
      background: $gold;
      color: $gray800;
    }
    &--coordinator {
      background: $green600;
      color: $white;
    }
  }
  &__select {
    .select__control {
      border-radius: 20px;
    }
    .select__value-container {
      padding-right: 0;
    }
    .select__option-container {
      @include body2;
      color: $gray800;
    }
    &--order-status {
      width: 160px;
    }
    &--payment-status {
      width: 184px;
    }
  }
  &__select-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .select__wrapper:not(.order-page__form:last-child) {
      width: fit-content;
      margin-bottom: 0;
    }
  }
  &__add-user-button {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    height: 40px;
    width: 40px;
    padding: 0;
    margin-left: auto;
    border-radius: 20px;
    @include lg {
      margin-bottom: 16px;
      width: 86px;
      height: 32px;
    }
  }
  &__menu {
    position: relative;
    svg.svg {
      margin-bottom: -7px;
      @include mobile-only {
        width: 17px;
      }
    }
  }
  &__menu-dropdown {
    position: absolute;
    width: 188px;
    top: 100%;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.09);
    border-radius: 4px;
    opacity: 0;
    pointer-events: none;
    z-index: -2;
    transition: 0.3s;
    &--show {
      opacity: 1;
      pointer-events: all;
      z-index: 2;
    }
  }
  tr:last-child,
  tr:nth-last-child(2) {
    .users-list__menu-dropdown {
      top: unset;
      bottom: 100%;
    }
  }
  &__menu-dropdown-item {
    height: 40px;
    color: $gray700;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    transition: 0.3s;
    vertical-align: middle;
    display: flex;
    align-items: center;
    padding-left: 16px;
    text-align: left;
    cursor: pointer;
    @include lg {
      &:hover {
        background: $gray100;
      }
    }
  }
  th {
    text-align: start;
    @include body2;
    color: #808080;
    @include lg {
      @include body1;
      color: #808080;
    }
    &:first-child {
      padding-left: 11px;
      @include lg {
        padding-left: 37px;
      }
    }
  }
  &__badge {
    background: #d9d9d9;
    border-radius: 20px;
    padding: 0px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: min-content;
    font-weight: 400;
    font-size: 10px;
    line-height: 134%;
    color: $gray800;
    &--suspended {
      background: #f2f2f2;
    }
    &--active {
      background-color: #a0e9d8;
      color: $gray800;
    }
  }
}
