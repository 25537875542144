.add-translation-modal {
  &__input {
    position: relative;
    &:after {
      content: '/ znak';
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $gray400;
      position: absolute;
      right: 16px;
      top: 37px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &__buttons {
    margin-top: 25px;
    grid-template-columns: 1fr 1fr;
    .btn {
      height: 48px;
    }
  }
}
