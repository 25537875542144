.select {
  &__option-container {
    display: flex;
    max-height: 28px;
    height: 24px;

    align-items: center;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: $gray700;
  }
  div &__multi-value__remove {
    cursor: pointer;
    padding: 0;
    margin-left: 5px;
    svg {
      width: 16px;
      height: 16px;
      fill: $gray-6;
    }
  }
  div &__multi-value {
    border-radius: 20px;
    padding: 4px 5px 4px 10px;
    margin: 0;
    display: flex;
    min-width: 0;
    background-color: $gray100;
    box-sizing: border-box;
    margin-right: 5px;
    &:last-of-type {
      margin-right: 0px;
    }
    span {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: $gray700;
    }
    .svg {
      width: 16px;
      height: 11px;
    }
    .select__icon {
      width: 17px;
      height: 13px;
    }
    &__label {
      padding: 0;
      display: flex;
    }
    .select__option-container {
      height: unset;
    }
  }

  .select__option-container {
    .svg {
      margin-right: 16px;
    }
  }
  div &__control {
    cursor: pointer;
  }
  div &__menu {
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.12);
    margin-top: 5px;
  }
  div &__menu-list {
    padding: 0;
    border-radius: 4px;
    max-height: 336px;
  }
  div &__option {
    padding: 0 16px;
    display: flex;
    align-items: center;
    height: 48px;
    cursor: pointer;
    background-color: $white;
    @include lg {
      &:hover {
        background-color: $background1;
      }
    }
    &--is-selected {
      background-color: $gray-5;
    }
    &:active {
      background-color: $gray-5;
    }
  }
  &__indicator-separator {
    display: none;
  }
  div &__input-container {
    margin: 0;
  }
  div &__value-container {
    padding: 0px 16px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
  }
  #react-select-7-input {
    display: none;
  }
  div &__control {
    border: 1px solid $midGray;
    background-color: $white;
    box-shadow: none;
    height: 48px;
    @include lg {
      &:hover {
        border: 1px solid $gray400;
      }
    }
    &--is-focused {
      border: 1px solid $green600;
    }
  }
  &__icon {
    width: 27px;
    max-height: 20px;
    margin-right: 15px;
    border-radius: 2px;
    &--small {
      width: 17px;
      max-height: 13px;
      height: 13px;
      margin-right: 8px;
    }
  }
  &__label {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    margin-bottom: 4px;
    color: $gray700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--optional {
      justify-content: flex-start;
    }
  }
  &__add-more {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: $green;
    cursor: pointer;
  }
  &__optional {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $gray600;
    margin-left: 5px;
  }
  div &__indicator {
    color: $gray400;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    &--disabled {
      .select__label {
        color: $gray400;
      }
      .select__placeholder {
        color: $gray200;
      }
      .select__indicator {
        color: $gray200;
      }
      .select__control {
        border-color: $gray200;
      }
    }
  }
  div &__placeholder {
    color: $gray400;
  }
  div &--alone {
    .select__multi-value {
      background-color: transparent;
      padding: 0;
    }
    .select__multi-value__label {
      font-size: 100%;
    }
    .select__multi-value span {
      font-size: 14px;
      line-height: 17px;
    }
    .select__multi-value .svg {
      width: auto;
      height: 21px;
    }
    .select__icon {
      width: 27px;
      height: 20px;
    }
    .select__multi-value__remove {
      display: none;
    }
    .select__value-container--is-multi + .select__indicators {
      display: flex;
    }
  }
  &--clearable {
    .select__indicator-separator {
      display: flex;
    }
    .select__indicators {
      flex-direction: row-reverse;
    }
  }
  &--error {
    .select__control {
      border-color: $error;
    }
  }
}
