@import './components/Languages.scss';
@import './components/addTranslationModal.scss';
@import './components/Filters.scss';

.translations-list {
  width: 100%;
  max-width: 1180px;
  padding: 0 20px;
  margin: 0 auto;
  min-height: calc(100vh - 56px);
  padding-bottom: 40px;
  &__header {
    @include heading1;
  }
  &__thead {
    @include body1;
    color: $gray500;
    height: 32px;
  }
  &__pagination {
    margin-top: 32px;
  }
  &__table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
  }
  th {
    text-align: start;
    @include body2;
    color: #808080;
    @include lg {
      padding-bottom: 16px;
      vertical-align: bottom;
      @include body1;
      color: #808080;
    }
    &:last-child {
      @include lg {
        padding-bottom: 0;
      }
    }
    &:first-child {
      padding-left: 11px;
      @include lg {
        padding-left: 37px;
      }
    }
  }
  tr:last-child {
    .users-list__menu-dropdown {
      top: unset;
      bottom: 100%;
    }
  }
  &__header-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    padding-bottom: 28px;
  }
  &__filter-dot {
    width: 10px;
    opacity: 0;
    color: $green500;
    fill: $green500;
    position: absolute;
    top: 0;
    right: 0;
    &--has-filters {
      opacity: 1;
    }
  }
  &__td {
    text-align: left;
    vertical-align: middle;
    border-collapse: collapse;
    height: 56px;
    min-width: 50px;
    color: $gray800;
    font-weight: 400;
    font-size: 12px;
    line-height: 138%;
    &:first-child {
      padding-left: 11px;
      border: solid transparent;
      border-style: none solid solid none;
      border-radius: 5px 0 0 5px;

      @include lg {
        max-width: 120px;
        padding-left: 37px;
      }
    }
    &:last-child {
      padding-right: 25px;
      text-align: right;
      border: solid transparent;
      border-style: none none solid none;
      border-radius: 0 5px 5px 0;
      @include lg {
        padding-right: 0;
      }
    }
    svg.svg--arrow-right {
      margin-bottom: -7px;
    }
  }
  &__language {
    display: flex;
    align-items: center;
    width: fit-content;
    background: $gray100;
    border-radius: 20px;
    padding: 2px 14px;
    @include body2;
    color: $gray700;
    height: 24px;
    img {
      margin-right: 8px;
      border: 0.5px solid #e0e2df;
      border-radius: 2px;
      box-sizing: border-box;
      height: auto;
    }
  }
  &__tr {
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
    margin-bottom: 4px;
    border-radius: 5px;
    transition: 0.3s;
    background-color: white;
    cursor: pointer;
    @include lg {
      &:hover {
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.18);
      }
    }
    &--active {
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.18);
    }
    &--inactive {
      opacity: 0.65;
    }
    div .select__control {
      height: 32px;
      min-height: unset;
    }
    .select__indicator {
      padding: 0 8px;
    }
    .svg {
      margin-bottom: 3px;
    }
  }
  &__select {
    .select__control {
      border-radius: 20px;
    }
    .select__value-container {
      padding-right: 0;
    }
    .select__option-container {
      @include body2;
      color: $gray800;
    }
    &--order-status {
      width: 160px;
    }
    &--payment-status {
      width: 184px;
    }
  }
  &__select-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .select__wrapper:not(.order-page__form:last-child) {
      width: fit-content;
      margin-bottom: 0;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    @include lg {
      margin-top: -4px;
      margin-bottom: 23px;
    }
  }
  &__top-text {
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    line-height: 145%;
    height: 32px;
    color: #767676;
    transition: 0.3s;
    padding: 0 20px 0 16px;
    border-radius: 20px;
    align-items: center;
    display: flex;
    cursor: pointer;
    &--active {
      background: $gray500;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      color: white;
      cursor: default;
    }
    &:last-child {
      margin-left: 17px;
    }
  }

  &__form {
    display: flex;
    justify-content: space-between;
    .input {
      height: 40px;
    }
    .input__label {
      margin-bottom: 3px;
    }
  }

  &__header-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 28px;
    .input__wrapper {
      width: 273px;
    }
    .select__wrapper {
      width: 273px;
      &:last-of-type {
        margin-left: 16px;
      }
      div .select__control {
        height: 40px;
      }
    }
  }

  &__status {
    padding: 5.5px 18px;
    border-radius: 20px;
    @include body2;
    color: $gray800;
    width: min-content;
    &--active {
      background: #a0e9d8;
    }
    &--inactive {
      background: $gray200;
    }
  }
  &__add-user-button {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    height: 40px;
    width: 40px;
    padding: 0;
    margin-left: auto;
    border-radius: 20px;
    @include lg {
      // margin-right: auto;
      margin-bottom: 16px;
      width: 86px;
      height: 32px;
    }
  }
}
