.contact-form {
  @include lg {
    margin-top: 56px;
  }
  &__header {
    display: flex;
    margin-bottom: 28px;
    @include lg {
      margin-bottom: 32px;
    }
  }
  &__header-item {
    &:first-child {
      margin-right: 8px;
    }
    font-weight: 600;
    font-size: 14px;
    line-height: 145%;
    color: $gray500;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    height: 32px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    &--active {
      background: #808080;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      color: #f2f2f2;
      cursor: default;
    }
  }
  &__tooltip-wrapper {
    position: absolute;
    left: calc(100% - 20px);
    &:hover {
      .contact-form__tooltip {
        opacity: 1;
      }
    }
  }
  &__tooltip {
    opacity: 0;
    position: absolute;
    padding: 14px;
    width: 239px;
    font-weight: 400;
    font-size: 10px;
    line-height: 134%;
    color: $gray600;
    transition: 0.3s;
    background: #ffffff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    z-index: 2;
    bottom: calc(100% + 4px);
    right: 0;
    pointer-events: none;
  }
  #nip {
    @include mobile-only {
      margin-bottom: 24px;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__row {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 24px;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    @include lg {
      align-items: flex-start;
      flex-direction: row;
    }
    &--splited {
      margin-bottom: unset;
      position: relative;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
      .contact-form__input:first-child {
        margin-bottom: 0;
        margin-right: 8px;
      }
      &:first-child {
        @include lg {
          margin-right: 16px;
        }
      }
      .contact-form__input:nth-child(2) {
        @include lg {
          margin-left: 0;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
  &__input {
    &:first-child {
      margin-bottom: 24px;
      @include lg {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
    @include lg {
      max-width: 321px;
    }
    &--small {
      max-width: 74px;
    }
    &--radio {
      max-width: 100%;
      flex: 1;
      width: 100%;
    }
    &:last-child {
      @include lg {
        margin-right: 0;
      }
    }
  }
}
