.languages-list {
  &__top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &__form {
    width: 100%;
  }
  &__search {
    width: 100%;
    margin-bottom: 32px;
    .input__label {
      display: none;
    }
    .input__removable {
      top: 12px;
      @include lg {
        top: 8px;
      }
    }
  }
  &__button {
    width: 86px;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    height: 32px;
    padding: 0;
    border-radius: 20px;
    margin-bottom: 16px;
  }
  &__wrapper {
    display: grid;
    gap: 16px;
    row-gap: 4px;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    @include lg {
      row-gap: 16px;
      margin-top: 4px;
      grid-template-columns: repeat(auto-fit, minmax(273px, 273px));
    }
  }
  &__language {
    // width: 280px;
    height: 48px;
    display: grid;
    grid-template-columns: 27px auto 23px;
    align-items: center;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    padding: 0px 16px 0px 25px;
    img {
      border: 0.5px solid #e0e2df;
      border-radius: 2px;
      box-sizing: border-box;
      height: auto;
    }
    p {
      margin-left: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.34;
      color: #5c5c5c;
    }
    .svg {
      cursor: pointer;
    }
    div.users-list__td {
      height: 48px;
    }
  }
}
