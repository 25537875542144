@import './components/SingleOrder.scss';
@import './components/ContactData.scss';
@import './components/AddTranslatedFileModal.scss';

.order-list {
  width: 100%;
  max-width: 1180px;
  padding: 0 20px 34px;
  margin: 0 auto;
  min-height: calc(100vh - 56px);
  padding-bottom: 40px;

  &__header {
    @include heading1;
    margin-bottom: 27px;
    padding-top: 28px;
  }
  &__thead {
    @include body1;
    color: $gray500;
    height: 32px;
  }
  &__pagination {
    margin-top: 32px;
  }
  th {
    text-align: start;
    @include body2;
    color: #808080;
    @include lg {
      height: 48px;
      @include body1;
      color: #808080;
      padding-bottom: 16px;
      vertical-align: bottom;
    }
    &:first-child {
      padding-left: 11px;
      @include lg {
        padding-left: 37px;
      }
    }
  }
  &__table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
  }
  &__td {
    text-align: center;
    vertical-align: middle;
    border-collapse: collapse;
    height: 56px;
    text-align: start;
    @include body2;
    color: $gray800;
    @include lg {
      @include body1;
      color: $gray800;
    }
    &:first-child {
      padding-left: 11px;
      border: solid transparent;
      border-style: none solid solid none;
      border-radius: 5px 0 0 5px;
      @include lg {
        padding-left: 37px;
      }
    }
    &:last-child {
      text-align: center;
      border: solid transparent;
      border-style: none none solid none;
      border-radius: 0 5px 5px 0;
    }
    &--deadline {
      color: $warning;
    }
    &--outdated {
      color: $error;
    }
  }
  &__search {
    &-wrapper {
      display: grid;
      grid-template-columns: min-content min-content min-content;
      grid-gap: 8px;
      margin-bottom: 24px;
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &-item {
      filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));
      border-radius: 20px;
      padding: 6px 16px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 145%;
      color: $gray500;
      transition: 0.3s;
      cursor: pointer;
      &--active {
        background: $gray500;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        filter: none;
        cursor: default;
        color: #fff;
      }
    }
  }
  &__customer {
    display: flex;
    align-items: center;
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 140px;
      white-space: nowrap;
      @include body1;
      margin-left: 8px;
      color: $gray800;
    }
  }
  &__customer-badge {
    height: 24px;
    width: 24px;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 800;
    font-size: 14px;
    line-height: 1.45;
    &--person {
      background: #d9d9d9;
      color: black;
    }
    &--company {
      background-color: $gray700;
      color: white;
    }
  }
  &__coordinator {
    position: relative;
    text-align: start;
    display: flex;
  }
  &__coordinator-menu {
    position: absolute;
    width: 200px;
    top: 100%;
    left: 0;
    background: #ffffff;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.09);
    border-radius: 4px;
    pointer-events: none;
    z-index: -2;
    transition: 0.3s;
    opacity: 0;
    max-height: 400px;
    overflow: auto;
    &--show {
      opacity: 1;
      // height: auto;
      pointer-events: all;
      z-index: 2;
      .order-list__coordinator-menu-item {
        height: 40px;
      }
    }
  }
  &__coordinator-menu-item {
    height: 0px;
    color: $gray700;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    transition: 0.3s;
    vertical-align: middle;
    display: flex;
    align-items: center;
    padding-left: 16px;
    @include lg {
      &:hover {
        background: $gray100;
      }
    }
  }
  &__coordinator-badge {
    background-color: $green600;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 800;
    font-size: 14px;
    line-height: 1.45;
    &--warning {
      @include heading1;
      font-size: 24px;
      color: $warning;
      margin-left: 8px;
    }
    &--admin {
      background-color: $gold;
      color: $gray700;
    }
    &--suspended {
      background-color: $gray500;
    }
  }
  &__tr {
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
    margin-bottom: 4px;
    border-radius: 5px;
    transition: 0.3s;
    background-color: white;
    cursor: pointer;
    @include lg {
      &:hover {
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.18);
      }
    }
    &--active {
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.18);
    }
    &--inactive {
      opacity: 0.65;
      .select__control {
        pointer-events: none;
      }
    }
    &--fade-out {
      opacity: 0;
      pointer-events: none;
    }
    div .select__control {
      height: 32px;
      min-height: unset;
    }
    .select__indicator {
      padding: 0 8px;
    }
    .svg {
      min-width: 18px;
      height: auto;
    }
  }
  tr:nth-child(7),
  tr:nth-child(8),
  tr:nth-child(9),
  tr:nth-child(10) {
    .order-list__coordinator-menu {
      top: unset;
      bottom: 100%;
    }
    .select__menu {
      top: unset;
      bottom: 100%;
    }
  }

  &__select {
    .select__control {
      @include lg {
        border-radius: 20px;
      }
    }
    .select__value-container {
      padding-right: 0;
    }
    .select__option-container {
      font-weight: 500;
      font-size: 14px;
      line-height: 134%;
      @include lg {
        @include body2;
      }
      color: $gray800;
    }
    &--order-status {
      @include lg {
        width: 160px;
      }
    }
    &--payment-status {
      @include lg {
        width: 184px;
      }
    }
    &--disabled {
      .select__option-container {
        color: $gray400;
      }
      border-color: $gray100;
      .svg--price-tag {
        path:last-child {
          stroke: $gray400;
        }
      }
      path:first-child {
        fill: $gray400;
      }
      .svg--stripe {
        path {
          stroke: unset;
          &:first-child {
            fill: $gray400;
          }
        }
      }
    }
    &--paid {
      .select__indicators {
        display: none;
      }
    }
  }
  &__select-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .select__wrapper:not(.order-page__form:last-child) {
      width: fit-content;
      margin-bottom: 0;
    }
  }
  &__principal {
    min-width: 174px;
  }
}
