.container-left {
  width: 100%;
  padding: 27px 0;
  padding-bottom: 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  &--certified-show-more {
    max-height: 481px;
    @include lg {
      max-height: unset;
    }
  }

  &--dragging {
    opacity: 0.4;
  }
  @include lg {
    transition: 0.3s;
    margin-bottom: 0;
    flex-basis: 658px;
    max-width: 658px;
    padding: 38px 0;
    padding-bottom: 0;
    margin-right: 113px;
  }
  &__slider {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    margin-left: -20px;
    padding: 0 20px;
    width: calc(100% + 40px);
    &--full-width {
      padding: 0;
    }

    @include lg {
      margin-left: -60px;
      width: 778px;
      padding: 0 60px;
      padding-right: 60px;
    }
  }
  &__slide {
    min-width: 100%;
    margin-right: 150px;
    @include lg {
      transition: 0.3s;
    }
    &--opened-menu {
      transition: 0.3s;
      @include lg {
        margin-bottom: 110px;
      }
    }
    &:last-of-type {
      margin-right: 0;
    }
    &--1 {
      transform: translateX(0);
      max-height: 627px;
      @include lg {
        max-height: unset;
      }
    }
    &--2 {
      transform: translateX(calc(-100% - 150px));
      max-height: 600px;
      @include lg {
        max-height: unset;
      }
    }
    &--3 {
      transform: translateX(calc(-200% - 300px));
    }
    &--certified {
      max-height: 426px;
      @include lg {
        max-height: unset;
      }
    }
    &--certified-show-more {
      max-height: unset;
    }
  }
  &--language-menu-open {
    @include lg {
      margin-bottom: 110px;
    }
  }
}
