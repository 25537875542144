.transaction-success {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 67px);

  &__wrapper {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 335px;
    width: 100%;
    @include lg {
      max-width: 369px;
    }
  }
  &__bold {
    font-weight: 900;
  }
  &__header {
    @include heading1;
    @include lg {
      font-size: 24px;
      white-space: nowrap;
    }
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    text-align: center;
    .svg {
      margin-left: 11px;
      @include lg {
        margin-left: 13px;
      }
    }
  }
  &__description {
    @include body2;
    color: $gray600;
    text-align: center;
    margin-bottom: 32px;
    margin-top: 12px;
  }
  &__message {
    @include heading3;
    color: $gray600;
    text-align: center;
    margin-bottom: 32px;
    &--no-margin {
      margin-bottom: 0;
    }
  }
  &__svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
    &--desktop {
      display: none;
      @include lg {
        display: block;
      }
    }
    &--mobile {
      display: block;
      @include lg {
        display: none;
      }
    }
  }
  .btn {
    max-width: 295px;
  }
}
