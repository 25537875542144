.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .switch__slider + .switch__slider--text {
      color: #272727 !important;
    }
    &:checked + .switch__slider + .switch__slider--text + .switch__slider--text {
      color: white !important;
    }
    &:checked + .switch__slider {
      background-color: #2eb092;

      &::before {
        -webkit-transform: translateX(17px);
        -ms-transform: translateX(17px);
        transform: translateX(17px);
      }
    }
  }
  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7f7f7f;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 1px solid #dddddd;
    &::before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 3px;
      bottom: 1.5px;
      background-color: #f2f2f2;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &--round {
      border-radius: 34px;
      &:before {
        border-radius: 30px;
      }
    }
  }
}
