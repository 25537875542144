.left-bar {
  width: 80px;
  min-height: 100vh;
  height: 100%;
  background-color: $gray800;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  transition: 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 5;
  @include lg {
    display: flex;
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 32px;
  }
  &__close {
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease;
    svg {
      width: 20px;
      height: 20px;
    }
    &--show {
      opacity: 1;
      pointer-events: all;
    }
  }
  &--open {
    width: 240px;
  }
  &__item-description {
    margin-left: 16px;
    font-size: 14px;
    color: $gray400;
    transition: 0.3s ease;
    white-space: nowrap;
  }
  &__navigation-item {
    min-width: 40px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    background: transparent;
    path {
      transition: all 0.3s ease;
      fill: white;
    }
    @include lg {
      :hover {
        path {
          fill: $green500;
        }
      }
    }
  }
  &__logo {
    cursor: pointer;
  }
  &__item-wrapper {
    max-width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-bottom: 16px;
    width: 100%;
    cursor: pointer;
    &--current {
      background: $gray700;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      path {
        fill: $green500;
      }
      .left-bar__item-description {
        color: $green500;
      }
    }
    @include lg {
      &:hover {
        .left-bar__item-description {
          color: $green500;
        }
        path {
          fill: $green500;
        }
      }
    }
  }
}
