@import './components/LanguageForm.scss';
@import './components/ServiceLevels.scss';
@import './components/Summary.scss';
@import './components/ContactForm.scss';
@import './components/IncorrectFIlesForm.scss';
@import './components/DeadlineModal.scss';
@import './components/CertifiedQuality.scss';

.order-page {
  margin-top: 28px;
  @include lg {
    margin-top: 76px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    div .order-page__quality {
      @include lg {
        margin-bottom: 0px;
      }
    }
    @include lg {
      flex-direction: row;
    }
    .select__wrapper,
    .radio__wrapper {
      margin-bottom: 24px;
      @include lg {
        margin-bottom: 32px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__file-input {
    display: none;
  }
  &__header {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: $gray800;
    &--right {
      margin-bottom: 25px;
      text-align: center;
      @include lg {
        text-align: left;
        margin-bottom: 35px;
      }
    }
  }
  &__translateTo-wrapper {
    display: flex;
    width: 100%;
    position: relative;
    .select__wrapper {
      margin-bottom: 0;
    }
    @include lg {
      margin-bottom: 16px;
      margin-bottom: 0;
      margin-left: 16px;
    }
    .select__multi-value:nth-child(n + 3) {
      display: none;
    }
    &--multi {
      .select__indicator {
        display: none;
      }
    }
  }
  &__quality-tooltip {
    p {
      @include body2;
      color: $gray600;
      margin-top: 8px;
    }
    @include lg {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      p {
        width: 321px;
        max-width: calc(50% - 8px);
      }
    }
    &--mobile {
      @include lg {
        display: none;
      }
    }
    &--desktop {
      display: none;
      @include lg {
        display: flex;
      }
    }
  }
  &__translateTo-indicator {
    position: absolute;
    right: 16px;
    top: 37px;
    @include body1;
  }
  &__subheader {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $gray700;
    margin-bottom: 24px;
  }
  &__text-below-button {
    font-weight: 400;
    font-size: 10px;
    line-height: 134%;
    color: $gray600;
    margin-top: 10px;
    // margin-inline: 23.5px;
    text-align: center;
    &--summary {
      text-align: left;
      line-height: 12px;
      margin-top: 16px;
      margin-bottom: 24px;
      margin-inline: unset;
    }
  }
  &__send-file {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    height: 48px;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
    @include lg {
      display: none;
    }
  }
  &__specialization {
    .select__menu-list {
      @include lg {
        max-height: 258px;
      }
    }
  }
  &__error {
    color: $error;
    font-size: 10px;
    line-height: 14px;
    margin-top: 8px;
    height: 0;
    transition: 0.3s;
    overflow: hidden;

    &--visible {
      height: 14px;
      margin-bottom: 24px;
    }
  }
  &__top-spacer {
    width: 54px;
  }
  div &__drop-file {
    width: 100%;
    border-radius: 6px;
    transition: 0.3s;
    .add-translated-file-modal__text--download {
      font-weight: 800;
    }
    height: unset;
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 25.5px 26.5px;
    @include lg {
      height: 176px;
      width: 100%;
    }
    &--drag-over {
      background: #f2f2f2;
      cursor: pointer;
    }
    &--error {
      border: 1px dashed $error;
      margin-bottom: 8px;
    }
    .add-translated-file-modal__text {
      color: $gray600;
    }
  }
  &__languages {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    @include lg {
      flex-direction: row;
      margin-bottom: 0;
      margin-top: 56px;
    }
  }
  &__continue-button {
    z-index: 1;
    border-radius: 30px;
  }
  &__form-top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    @include lg {
      margin-bottom: 0;
      justify-content: space-between;
      position: relative;
    }
    .back {
      display: none;
      @include lg {
        width: 80px;
        display: flex;
      }
    }
    .step {
      display: none;
      @include lg {
        display: flex;
      }
    }
  }
  &__add-file {
    justify-content: flex-start;
    .order-page__continue-button {
      @include lg {
        margin-top: 90px;
      }
    }
  }
  #quality {
    margin-bottom: 0;
  }
  &__hidden-flags {
    display: none;
  }
}
