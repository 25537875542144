.certified-quality {
  display: grid;
  margin-top: 8px;
  @include lg {
    margin-top: 48px;
    gap: 30px;
    grid-template-columns: 192px auto;
    gap: 97px;
  }
  &__card {
    width: 100%;
    max-width: 192px;
    padding: 25px 24px;
    background: #ffffff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @include lg {
      height: 348px;
      margin: 0;
    }
  }
  &__price {
    font-weight: 800;
    font-size: 20px;
    line-height: 145%;
    color: $gray800;
    text-align: center;
    margin-bottom: 24px;
  }
  &__date {
    font-weight: 700;
    font-size: 14px;
    line-height: 160%;
    color: $gray800;
    text-align: center;
  }
  &__date-wrapper {
    text-align: center;
  }
  &__date-header {
    font-weight: 400;
    font-size: 10px;
    line-height: 134%;
    color: $gray600;
    margin-bottom: 8px;
  }
  &__tooltip {
    font-weight: 400;
    font-size: 12px;
    line-height: 134%;
    color: $gray600;
    transition: 0.3s;
    text-align: center;
    margin-bottom: 17px;
    @include lg {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      text-align: left;
      font-size: 10px;
      padding: 14px;
      background: #ffffff;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      bottom: 16px;
      position: absolute;
      margin-bottom: 0;
    }
    strong {
      font-weight: 700;
      @include lg {
        font-weight: 400;
      }
    }
  }
  &__tooltip-header {
    font-weight: 400;
    font-size: 12px;
    line-height: 138%;
    color: $gray600;
    text-align: center;
    @include lg {
      text-align: left;
    }
  }
  &__br-desktop {
    display: none;
    @include lg {
      display: block;
    }
  }
  &__svg {
    margin-bottom: 32px;
    height: unset;
    display: none;
    @include lg {
      display: block;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
  }
  &__tooltip-show-more {
    font-weight: 600;
    font-size: 10px;
    line-height: 134%;
    text-decoration: underline;
    color: $green600;
    margin-top: 4px;
    display: none;
    cursor: pointer;
    @include lg {
      display: block;
      &:hover + .certified-quality__tooltip {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    }
    &--mobile {
      display: block;
      margin-top: 16px;
      @include lg {
        display: none;
      }
    }
  }
  &__tooltip-wrapper {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 0.3s;
    overflow: hidden;
    max-height: 0;
    @include lg {
      overflow: unset;
      max-height: unset;
      display: block;
      visibility: visible;
      pointer-events: all;
      opacity: 1;
    }
    &--show-more {
      margin-top: 30px;
      opacity: 1;
      max-height: 600px;
      visibility: visible;
      pointer-events: all;
    }
  }
}
