.service-levels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-inline: auto;
  width: calc(100%);
  align-items: center;
  height: 350px;
  @include lg {
    height: unset;
    width: calc(100% - 2px);
  }
  &--single {
    justify-content: center;
  }
  &__wrapper {
    width: 100%;
    overflow-x: auto;
    padding: 43px 0;
    margin-bottom: -300px;
    padding-bottom: 300px;
    overflow-y: hidden;
    @include lg {
      padding: 104px 0 0;
      overflow-x: unset;
      margin-bottom: unset;
      overflow-y: unset;
    }
  }
  &__card-overlay {
    position: absolute;
    bottom: 31px;
    left: 0;
    width: 100%;
    background: $white;
    z-index: 1;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 21px 15px;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.12);
    transition: 0.3s;
    div .select__control {
      height: 32px;
    }
    div.select__option {
      height: 32px;
      .select__option-container {
        font-weight: 400;
        font-size: 12px;
        line-height: 138%;
      }
      .svg {
        margin-right: 8px;
      }
    }
    &--show {
      display: flex;
      @include lg {
        opacity: 0;
        pointer-events: none;
      }
      .select__wrapper {
        margin-top: 9px;
      }
    }
    p {
      max-width: 121px;
      @include body2;
      text-align: center;
    }
    .select__required,
    .select__label {
      display: none;
    }
    .select__placeholder {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      white-space: nowrap;
    }
    .select__wrapper:not(.order-page__form:last-child) {
      margin-bottom: 0;
    }
    .select__menu {
      position: absolute;
    }
  }
  &__card-wrapper {
    padding: 20px 17px 28px;
    display: flex;
    flex-direction: column;
    flex: 1;
    // justify-content: space-between;
    // margin: auto 0;
    @include lg {
      margin: unset;
      // max-height: 204px;
      justify-content: unset;
    }

    path {
      fill: $green600;
    }
  }
  &__first-card {
    padding-left: calc(50vw - 136px);
    width: calc(100% - 50vw + 136px);
    margin-right: 16px;
    @include lg {
      padding-left: 0;
      width: 192px;
    }
  }
  &__last-card {
    padding-right: calc(50vw - 136px);
    width: calc(100% - 50vw + 136px);
    margin-left: 16px;
    @include lg {
      padding-right: 0;
      width: 192px;
    }
  }
  &__card {
    width: 191px;
    min-width: 191px;
    max-width: 191px;
    display: flex;
    flex-direction: column;
    background: $white;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    min-height: 300px;
    max-height: 300px;
    cursor: pointer;
    position: relative;
    transition: 0.8s;
    border: 1px solid transparent;
    box-sizing: content-box;
    // margin-right: 16px;
    &--hidden {
      display: none;
    }
    &--selected {
      border: 1px solid #d1b978;
      box-shadow: 4px 6px 40px rgba(0, 0, 0, 0.18);
    }
    &--not-selected {
      opacity: 0.8;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    }
    @include lg {
      width: 192px;
      min-width: 192px;
      max-width: 192px;
      min-height: 348px;
      max-height: 348px;
      &:hover {
        .service-levels__card-overlay {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
    &--center {
      min-height: 348px;
      max-height: 348px;
      .service-levels__price {
        font-weight: 800;
        font-size: 20px;
        line-height: 145%;
      }
      .service-levels__date {
        font-size: 14px;
        line-height: 160%;
      }
      .svg--recommendation {
        position: absolute;
        top: -20px;
        left: -11px;
        width: calc(100% + 22px);
        height: fit-content;
      }
      @include lg {
        min-height: 348px;
        max-height: 348px;
      }
    }
  }
  &__expert-not-available {
    font-weight: 400;
    font-size: 10px;
    line-height: 134%;
    color: $gray700;
    text-align: center;
    margin-bottom: 16px;
  }
  &__header {
    width: 100%;
    display: flex;
    height: 48px;
    min-height: 48px;
    border-radius: 6px 6px 0px 0px;
    justify-content: center;
    align-items: center;
    position: relative;
    @include heading2;
    // margin-bottom: 24px;
    color: #ffffff;
    background-color: $green600;
    &--standard {
      border-radius: 0;
    }
    &--disabled {
      background-color: $gray400;
    }
  }
  &__error {
    color: $error;
    font-weight: 400;
    font-size: 12px;
    line-height: 138%;
    text-align: center;
    height: 0;
    overflow: hidden;
    transition: 0.3s;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    &--visible {
      margin-top: 15px;
      height: 17px;
    }
  }
  &__check-wrapper {
    position: relative;
    height: 16px;
    width: 16px;
    margin-left: 11px;
    .svg--circle {
      position: absolute;
      top: 0px;
      left: 0;
      width: 16px;
      height: 16px;
      opacity: 1;
      transition: 0.3s;
      &-hidden {
        opacity: 0;
      }
    }
    .svg--checked {
      position: absolute;
      top: 0px;
      left: 0;
      width: 16px;
      height: 16px;
      opacity: 0;
      transition: 0.3s;
      &-visible {
        opacity: 1;
      }
    }
  }
  &__recommendation {
    background: #d1b978;
    height: 30px;
    color: #ffffff;
    font-weight: 800;
    font-size: 12px;
    line-height: 138%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -30px;
    width: 100%;
    border-radius: 6px 6px 0px 0px;
  }
  &__team {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    @include body2;
  }
  &__team-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }
  &__tooltip-wrapper {
    width: 100%;
    position: relative;
    &:hover {
      .service-levels__tooltip {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  &__tooltip-header {
    color: $green600;
    font-weight: 600;
    font-size: 10px;
    line-height: 134%;
    text-decoration-line: underline;
    text-align: center;
    margin-top: 16px;
  }
  &__tooltip {
    position: absolute;
    bottom: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 17px 13px;
    font-weight: 400;
    font-size: 10px;
    line-height: 134%;
    color: #666666;
    transition: 0.3s;
    opacity: 0;
    pointer-events: none;
  }
  &__team-item {
    display: flex;
    align-items: center;
    color: $gray800;
    font-weight: 400;
    font-size: 10px;
    line-height: 134%;
    .svg {
      margin-right: 2px;
    }
    &:first-child {
      margin-top: 0;
    }
    &--plus {
      margin-top: 0;
      margin-bottom: 1px;
      color: $gray400;
      line-height: 1;
    }
  }
  &__price {
    @include heading2;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-bottom: 19px;
    font-weight: 700;
    font-size: 14px;
    line-height: 160%;
    align-items: flex-end;
    transition: 0.3s;
    color: $gray800;
    @include lg {
      line-height: 145%;
      font-weight: 800;
      font-size: 20px;
    }
  }
  &__time {
    @include body2;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: $gray600;
  }
  &__date {
    color: $text;
    margin-top: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 138%;
    transition: 0.3s;
    @include lg {
      font-size: 14px;
      line-height: 160%;
    }
  }
  &__need-it-quicker {
    margin-top: 4px;
    @include body2;
    color: $green600;
    font-weight: 600;
    cursor: pointer;
  }
  &__contact {
    margin-top: 40px;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    color: $text;
    align-items: center;
    flex-direction: column;
    text-align: center;
    line-break: strict;
    & > a {
      @include mobile-only {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
    &-text {
      margin-bottom: 24px;
      gap: 4px;
      flex-direction: column;
      flex: 1;
      align-items: center;
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      @include lg {
        justify-items: start;
        grid-template-columns: 234px 131px;
        margin-right: 24px;
        margin-bottom: 0px;
        flex-wrap: wrap;
        row-gap: 0px;
        flex-direction: row;
        gap: 5px;
        margin-top: 0;
      }
      p {
        width: fit-content;
        white-space: nowrap;
      }
    }
    @include lg {
      text-align: left;
      flex-direction: row;
    }
    .btn {
      font-weight: 800;
      font-size: 14px;
      line-height: 17px;
      padding: 0;
      @include lg {
        &:hover {
          path {
            fill: $white;
          }
        }
      }
      @include lg {
        width: 192px;
        height: 40px;
      }
    }
  }
  &__mobile-br {
    display: block;
    @include lg {
      display: none;
    }
  }
  &__contact-us {
    span & {
      white-space: normal;
      max-width: 295px;
      @include lg {
        max-width: none;
        white-space: nowrap;
      }
    }
    &--mobile {
      // max-width: 295px;
      display: flex;
      width: 100%;
      z-index: 2;
      height: 40px;
      .svg {
        margin-left: 0;
      }
      @include lg {
        display: none;
      }
    }
    strong {
      font-weight: 700;
      margin-left: 4px;
    }
    &--desktop {
      display: none;
      @include lg {
        display: flex;
      }
    }
    @include lg {
      display: flex;
    }
  }
  &--disabled {
    color: $disabled;
    path {
      fill: $disabled;
    }
  }
  &__phone {
    display: flex;
    align-items: center;
    margin-top: 16px;
    @include heading3;
    @include lg {
      display: none;
    }
  }
}
